.loader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: #f0f0f0;
}

.map-marker-wrapper {
  position: relative;
  width: 60px;
  height: 60px;
  margin-bottom: 42px;
}

.map-marker,
.map-marker-pulse {
  position: absolute;
  width: 60px;
  height: 60px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.map-marker::before,
.map-marker-pulse::before {
  content: "";
  position: absolute;
  width: 60px;
  height: 60px;
  left: 0;
  top: 0;
  background-color: #004f89;
  border-radius: 50% 50% 50% 0;
  transform: rotate(-45deg);
}

.map-marker::after,
.map-marker-pulse::after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  left: 50%;
  top: 50%;
  background-color: #f0f0f0;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.map-marker-pulse {
  animation: markpulse 1s ease-out infinite;
}

@keyframes markpulse {
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -50%) scale(1.5);
    opacity: 0;
  }
}

.title {
  font-size: 32px;
}
