@keyframes pulse {
  0% {
    transform: translate(-50%, -50%) scale(1.1);
  }

  70% {
    transform: translate(-50%, -50%) scale(0.9);
    box-shadow: 0 0 0 9px rgba(0, 0, 0, 0);
  }

  100% {
    transform: translate(-50%, -50%) scale(1.1);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

@keyframes pulsar {
  0% {
    transform: scale(1.06);
  }

  70% {
    transform: scale(0.97);
  }

  100% {
    transform: scale(1.06);
  }
}
