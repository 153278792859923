.superselect-container {
  height: 36px;

  &:hover {
    .superselect__single-value {
      color: $primary-contrast !important;
    }

    .superselect__placeholder {
      color: $primary !important;
    }
  }
}

.superselect__value-container--has-value {
  .superselect__single-value {
    color: $primary !important;
  }
}

.superselect__menu {
  border: 1px solid $medium;
  z-index: 3 !important;

  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  background: white;

  scrollbar-width: thin;
  padding-bottom: 0;
  padding-top: 0;
  margin-top: 0 !important;
  margin-bottom: 0;
}

.superselect__option {
  padding-left: 1.5rem !important;

  cursor: pointer !important;
}

.superselect__option--is-selected {
  color: $primary !important;
  background: $light_blue !important;
  padding-left: 0.5rem !important;

  &::before {
    content: '✓ ' !important;
  }
}

.superselect__menu-list {
  max-height: 500px !important;
}

.superselect__single-value {
  font-size: 12px;
  color: $medium !important;
}

.superselect__placeholder {
  font-size: 12px;

  color: $medium !important;
}

.superselect__control {
  min-height: 100% !important;

  border: 1px solid transparent !important;
  box-shadow: $component-shadow !important;
  cursor: pointer !important;

  input,
  input:focus {
    border: 0 !important;
  }

  &.superselect__control--is-focused {
    box-shadow: none !important;
    border: 1px solid $primary !important;
    color: $light !important;

    border-radius: $border-radius !important;
  }

  &.superselect__control--menu-is-open {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  &:hover {
    .superselect__placeholder {
      color: $primary !important;
    }
    .superselect__single-value {
      color: $primary !important;
    }
  }
}

.noSearch {
  top: -40px;
}

.superselect__value-container {
  padding-top: 0;
  padding-bottom: 0;
}

.superselect__menu-list {
  padding: 0 !important;
}

.superselect__indicator {
  display: none !important;
}

.superselect__indicators {
  display: none !important;
}

.superselect__indicator-separator {
  display: none !important;
}
