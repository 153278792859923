.react-multi-email {
  background: white !important;
  border: 0 !important;
  box-shadow: $component-shadow;

  input {
    box-shadow: none;
  }

  span {
    font-size: 12px !important;
    color: $medium !important;
    opacity: 1 !important;
  }

  &.focused {
    border: 1px solid $blue !important;
  }

  div {
    background: $gray !important;
  }

  &:hover {
    span {
      color: $primary !important;
    }
  }
}
