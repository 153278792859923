// global overrides
input {
  box-shadow: $component-shadow;

  &::placeholder {
    font-size: 12px;
    color: $medium !important;
    opacity: 1;
  }
}

.bg-primary {
  color: $primary !important;
}

.text-blue {
  color: $blue;
}

.text-underline {
  text-decoration: underline;
}

.cursor-pointer {
  cursor: pointer;
}

// fix for gutton toggle z-index
.btn {
  border: 1px solid transparent !important;
  padding-top: map-get($spacers, 1);
  padding-bottom: map-get($spacers, 1);
  font-size: 12px;

  &.active {
    z-index: 0 !important;
  }
}

.btn-primary {
  color: white !important;
  border: 1px solid transparent !important;

  &:hover {
    border: 1px solid $dark !important;
    background: white !important;
    color: $dark !important;

    small {
      color: $dark !important;
    }
  }

  small {
    color: white !important;
  }
}

.dropdown-item {
  &:focus {
    small {
      color: white;
    }
  }
}

.bs-tooltip-right {
  .arrow::before {
    border-right-color: $dark;
  }
}

.bs-tooltip-bottom {
  .arrow::before {
    border-bottom-color: $dark;
  }
}

.bs-tooltip-top {
  .arrow::before {
    border-top-color: $dark;
  }
}

.bs-tooltip-left {
  .arrow::before {
    border-left-color: $dark;
  }
}

.tooltip-inner {
  border: 1px solid $dark;
}

.nav-link {
  color: $dark;
  border: 0 !important;
  text-decoration: none !important;

  &:hover {
    border: 0;
    color: $primary !important;
  }

  &.active {
    border: 0;
    border-bottom: 2px solid $primary !important;
    color: $primary;
  }
}
