.react-datepicker {
  display: flex;

  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.react-datepicker-wrapper {
  height: 36px !important;
  width: 100px;

  border: 0 !important;

  input:focus {
    z-index: 2;
  }

  input::placeholder {
    color: $medium !important;
  }

  &:hover {
    input::placeholder {
      color: $primary !important;
      cursor: pointer !important;
    }
  }
}

.react-datepicker__month-container,
.react-datepicker__time-container {
  float: none;
}

.react-datepicker-popper {
  left: auto !important;
  right: 0px;
  transform: translate(-20px, 55px) !important;

  z-index: 3;
}

.react-datepicker__triangle {
  position: absolute !important;
  left: 210px !important;
}

.react-datepicker__input-container {
  height: 100% !important;

  border: 0 !important;
}

.react-datepicker__header {
  background: $dark;

  border-top-left-radius: 0;
}

.react-datepicker__header--time {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 65px;

  border-top-right-radius: 0;
  border-top-left-radius: $border-radius;
}

.react-datepicker__time-container {
  position: absolute;
  left: -85px;
  top: -1px;
  border: 0 !important;
  border-top: 0 !important;
  border-left: 1px solid rgb(174, 174, 174) !important;
  border-bottom: 1px solid rgb(174, 174, 174) !important;

  border-bottom-left-radius: $border-radius;

  .react-datepicker__time,
  .react-datepicker__time-box,
  .react-datepicker__time-list {
    border-bottom-left-radius: $border-radius;
  }
}

.react-datepicker__navigation--previous {
  left: 30px !important;
}

.react-datepicker__navigation--next {
  right: 30px !important;
}

.react-datepicker__current-month,
.react-datepicker__day-name,
.react-datepicker-time__header {
  color: white;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected,
.react-datepicker__time-list-item--selected,
.react-datepicker__day--in-range {
  background-color: $primary !important;
  color: white !important;
}

.react-datepicker__day:hover {
  background-color: $primary-contrast !important;
  color: white !important;
}

.react-datepicker-wrapper {
  input {
    &:focus {
      border: 1px solid $primary !important;
      border-right: 0 !important;
    }
  }
}

.react-datepicker-wrapper:nth-of-type(1) {
  .react-datepicker__input-container {
    padding-right: 3px !important;
  }
}

.react-datepicker-wrapper:nth-of-type(1) {
  input,
  .react-datepicker-ignore-onclickoutside {
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;

    border: 1px solid transparent !important;
  }
}

.react-datepicker-wrapper:nth-of-type(2),
.react-datepicker-wrapper:nth-of-type(3) {
  input,
  .react-datepicker-ignore-onclickoutside {
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;

    border: 1px solid transparent !important;
  }
}
